html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: 'SourceSansPro', Helvetica, Arial, sans-serif;
}

* {
  box-sizing: border-box;
}
